<template>
    <div class="dialog-container">
        <el-dialog
            width="45%"
            title
            :visible.sync="visible"
            :show="show"
            class="tank"
            :before-close="handleDialogClose"
            @close="handleDialogClose"
        >
            <h2>
                <span
                    v-for="(item, index) in titleName"
                    :key="index"
                    :class="{ active: index == isShow1 }"
                    @click="selectTitle(index)"
                >{{ item }}</span>
            </h2>
            <!-- 线索信息 -->
            <div v-show="isShow1 == 0" class="clue">
                <div class="clue-left">
                    <div class="l-top">
                        <div class="flex-sb title-info" style="align-items:center">
                            <div style="font-weight: 400;">基本信息</div>
                            <el-popover placement="bottom" width="50" trigger="hover">
                                <div>意向度：{{ ClueInformation.purposeLevel }}</div>
                                <div
                                    style="margin-top:10px"
                                >资质星级：{{ ClueInformation.qualityLevel + "星" }}</div>
                                <div />
                                <el-button slot="reference" type="text">线索质量</el-button>
                            </el-popover>
                        </div>
                        <div class="contant">
                            <!-- 姓名 -->
                            <div class="flex-sb item-info">
                                <div class="flex">
                                    <img
                                        width="15"
                                        height="15"
                                        src="@/assets/clue/detail-icon/name.svg"
                                        alt
                                    />
                                    <div>姓名：</div>
                                </div>
                                <div>{{ ClueInformation.name }}</div>
                            </div>
                            <!-- 手机号 -->
                            <div class="flex-sb item-info">
                                <div class="flex">
                                    <img
                                        width="15"
                                        height="15"
                                        src="@/assets/clue/detail-icon/mobile.svg"
                                        alt
                                    />
                                    <div>手机号：</div>
                                </div>
                                <div>{{ common.hidePhoneNumber( ClueInformation.mobileNumber ) }}</div>
                            </div>
                            <!-- 资金需求： -->
                            <div class="flex-sb item-info">
                                <div class="flex">
                                    <img
                                        width="15"
                                        height="15"
                                        src="@/assets/clue/detail-icon/money-need.svg"
                                        alt
                                    />
                                    <div>资金需求：</div>
                                </div>
                                <div>{{ ClueInformation.fundNeeds }}万</div>
                            </div>
                            <!-- 性别 -->
                            <div class="flex-sb item-info">
                                <div class="flex">
                                    <img
                                        width="15"
                                        height="15"
                                        src="@/assets/clue/detail-icon/sex.svg"
                                        alt
                                    />
                                    <div>性别：</div>
                                </div>
                                <div>{{ ClueInformation.sex }}</div>
                            </div>
                            <!-- 年龄 -->
                            <div class="flex-sb item-info">
                                <div class="flex">
                                    <img
                                        width="15"
                                        height="15"
                                        src="@/assets/clue/detail-icon/age.svg"
                                        alt
                                    />
                                    <div>年龄：</div>
                                </div>
                                <div>{{ ClientInformation.age ? ClientInformation.age + "岁": "未知" }}</div>
                            </div>

                            <!-- 地域 -->
                            <div class="flex-sb item-info">
                                <div class="flex">
                                    <img
                                        width="15"
                                        height="15"
                                        src="@/assets/clue/detail-icon/address.svg"
                                        alt
                                    />
                                    <div>地域：</div>
                                </div>
                                <div>{{ ClueInformation.residence }}</div>
                            </div>

                            <!-- 来源渠道 -->
                            <div class="flex-sb item-info">
                                <div class="flex">
                                    <img
                                        width="15"
                                        height="15"
                                        src="@/assets/clue/detail-icon/info-from.svg"
                                        alt
                                    />
                                    <div>来源渠道：</div>
                                </div>
                                <div>{{ ClueInformation.source }}</div>
                            </div>

                            <div class="flex-sb item-info">
                                <div class="flex">
                                    <img
                                        width="15"
                                        height="15"
                                        src="@/assets/clue/detail-icon/name.svg"
                                        alt
                                    />
                                    <div>客户资质：</div>
                                </div>
                                <div v-show="ClueInformation.isAllNone">未知</div>
                                <div v-show="!ClueInformation.isAllNone" class="hasIcon flex">
                                    <div v-show="ClueInformation.hasVehicle == 1" class="car">车</div>
                                    <div v-show="ClueInformation.hasHouse == 1" class="house">房</div>
                                    <div
                                        v-show="ClueInformation.hasGongjijin == 1"
                                        class="gongjijin"
                                    >金</div>
                                    <div v-show="ClueInformation.hasShebao == 1" class="shebao">社</div>
                                    <div v-show="ClueInformation.hasBaodan == 1" class="baodan">保</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="l-bottom">
                        <div class="flex-sb">
                            <div class style="height:40px;line-height:40px;font-weight:400">其他信息</div>
                        </div>
                        <div class="contant" style="height: 138px;">
                            <div class="remark-item">
                                话术提醒：
                                <font style="color: #AAAAAA;font-size: 14px;">
                                    {{ ClueInformation.scriptPrompt ? ClueInformation.scriptPrompt : "暂无" }}
                                </font>
                            </div>
                            <div class="remark-item">
                                线索备注：
                                <font style="color: #AAAAAA;font-size: 14px;">
                                    {{ ClueInformation.remarks ? ClueInformation.remarks : "暂无" }}
                                </font>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clue-right">
                    <!-- <h4>操作记录</h4> -->
                    <!-- <div class="title-info" style="height：40px;line-height:40px">
                    操作记录</div>-->
                    <div class="jl">
                        <el-tabs v-model="activeName" stretch>
                            <el-tab-pane label="操作记录" name="first">
                                <div class="timeblock">
                                    <el-timeline>
                                        <el-timeline-item
                                            v-for="(item, index) in ClueInformation.operateLogs"
                                            :key="index"
                                            type="primary"
                                        >
                                            <div class="flex-center">
                                                <div>{{ item.operatedTime }}</div>
                                                <div>({{ item.operator }})</div>
                                            </div>

                                            <div class="time-line-content">{{ item.content }}</div>
                                        </el-timeline-item>
                                    </el-timeline>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="通话记录" name="second">
                                <div class="timeblock">
                                    <el-timeline>
                                        <el-timeline-item
                                            v-for="(item, index) in personClueData"
                                            :key="index"
                                            type="primary"
                                        >
                                            <div class="flex-center">
                                                <div>{{ item.createdTime|getYMDHMS }}</div>
                                                <div>({{ item.callerName }})</div>
                                            </div>
                                            <div class="audio-record">
                                                <div
                                                    class="record-item"
                                                >{{ item.callerName }}与{{ item.calledName }}的通话</div>
                                                <div class="record-item">
                                                    <callAudio
                                                        :src="item.audioUrl"
                                                        :font-size="'10px'"
                                                    />
                                                </div>
                                            </div>
                                        </el-timeline-item>
                                    </el-timeline>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
            <!-- 客户信息 -->
            <div v-show="isShow1 == 1" class="clue client">
                <div v-show="clientShow" class="baoguo">
                    <div class="clue-left">
                        <div class="l-top">
                            <div class="flex-sb title-info">
                                <div
                                    style="font-weight: 400;"
                                >基本信息（{{ "ID：" + ClientInformation.id }}）</div>
                            </div>
                            <div class="contant">
                                <!-- 姓名 -->
                                <div class="flex-sb item-info">
                                    <div class="flex">
                                        <img
                                            width="15"
                                            height="15"
                                            src="@/assets/clue/detail-icon/name.svg"
                                            alt
                                        />
                                        <div>姓名：</div>
                                    </div>
                                    <div>{{ ClientInformation.name ? ClientInformation.name : "未知" }}</div>
                                </div>
                                <!-- 手机号 -->
                                <div class="flex-sb item-info">
                                    <div class="flex">
                                        <img
                                            width="15"
                                            height="15"
                                            src="@/assets/clue/detail-icon/mobile.svg"
                                            alt
                                        />
                                        <div>手机号：</div>
                                    </div>
                                    <div>{{ ClientInformation.mobileNumber ? common.hidePhoneNumber( ClientInformation.mobileNumber ) : "未知" }}</div>
                                </div>
                                <!-- 资金需求： -->
                                <div class="flex-sb item-info">
                                    <div class="flex">
                                        <img
                                            width="15"
                                            height="15"
                                            src="@/assets/clue/detail-icon/money-need.svg"
                                            alt
                                        />
                                        <div>资金需求：</div>
                                    </div>
                                    <div>{{ ClientInformation.fundNeeds ? ClientInformation.fundNeeds + "万": "未知" }}</div>
                                </div>
                                <!-- 性别 -->
                                <div class="flex-sb item-info">
                                    <div class="flex">
                                        <img
                                            width="15"
                                            height="15"
                                            src="@/assets/clue/detail-icon/sex.svg"
                                            alt
                                        />
                                        <div>性别：</div>
                                    </div>
                                    <div>{{ ClientInformation.sex ? ClientInformation.sex : "未知" }}</div>
                                </div>
                                <!-- 年龄 -->
                                <div class="flex-sb item-info">
                                    <div class="flex">
                                        <img
                                            width="15"
                                            height="15"
                                            src="@/assets/clue/detail-icon/age.svg"
                                            alt
                                        />
                                        <div>年龄：</div>
                                    </div>
                                    <div>{{ ClientInformation.age ? ClientInformation.age + "岁" : "未知" }}</div>
                                </div>

                                <!-- 地域 -->
                                <div class="flex-sb item-info">
                                    <div class="flex">
                                        <img
                                            width="15"
                                            height="15"
                                            src="@/assets/clue/detail-icon/address.svg"
                                            alt
                                        />
                                        <div>地域：</div>
                                    </div>
                                    <div>{{ ClientInformation.residence ? ClientInformation.residence: "未知" }}</div>
                                </div>

                                <!-- 跟进阶段： -->
                                <div class="flex-sb item-info">
                                    <div class="flex">
                                        <img
                                            width="15"
                                            height="15"
                                            src="@/assets/clue/detail-icon/follow-stage.svg"
                                            alt
                                        />
                                        <div>跟进阶段：</div>
                                    </div>
                                    <div>{{ ClientInformation.followStage ? ClientInformation.followStage : "未知" }}</div>
                                </div>

                                <!-- 入库时间： -->
                                <div class="flex-sb item-info" style="align-items: center;">
                                    <div class="flex">
                                        <img
                                            width="15"
                                            height="15"
                                            src="@/assets/clue/detail-icon/warehouse-time.svg"
                                            alt
                                        />
                                        <div>入库时间：</div>
                                    </div>
                                    <div>{{ ClientInformation.createdTime | getYMDHMS }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="l-bottom">
                            <h4>
                                <span>客户资质</span>
                            </h4>
                            <div class="contant">
                                <div class="quantity-item flex">
                                    <div class="car">车</div>
                                    <div
                                        v-if="ClientInformation.vehicle.nakedPrice && ClientInformation.vehicle.has == 1"
                                    >裸车价：{{ ClientInformation.vehicle.nakedPrice }}万；</div>
                                    <!-- v-if="ClientInformation.vehicle.paymentType" -->
                                    <div
                                        v-if=" ClientInformation.vehicle.paymentType && ClientInformation.vehicle.has == 1"
                                    >付款：{{ ClientInformation.vehicle.paymentType }}；</div>
                                    <div v-if="ClientInformation.vehicle.has != 1">未知</div>
                                </div>
                                <div class="quantity-item flex">
                                    <div class="house">房</div>
                                    <div
                                        v-if=" ClientInformation.house.marketPrice && ClientInformation.house.has == 1"
                                    >房价：{{ ClientInformation.house.marketPrice }}万；</div>
                                    <div
                                        v-if="ClientInformation.house.paymentType &&ClientInformation.house.has == 1 "
                                    >付款：{{ ClientInformation.house.paymentType }}；</div>
                                    <div
                                        v-if=" ClientInformation.house.type && ClientInformation.house.has == 1"
                                    >类型：{{ ClientInformation.house.type }}；</div>
                                    <div v-if="ClientInformation.house.has != 1">未知</div>
                                </div>
                                <div class="quantity-item flex">
                                    <div class="gongjijin">金</div>
                                    <div
                                        v-if=" ClientInformation.gongjijin.monthlyFee && ClientInformation.gongjijin.has == 1"
                                    >月缴：{{ ClientInformation.gongjijin.monthlyFee }}元；</div>
                                    <div
                                        v-if="ClientInformation.gongjijin.payYears && ClientInformation.gongjijin.has == 1 "
                                    >年限：{{ ClientInformation.gongjijin.payYears }}年；</div>
                                    <div
                                        v-if="ClientInformation.gongjijin.type &&ClientInformation.gongjijin.has == 1"
                                    >类型：{{ ClientInformation.gongjijin.type }}；</div>
                                    <div v-if="ClientInformation.gongjijin.has != 1">未知</div>
                                </div>
                                <div class="quantity-item flex">
                                    <div class="shebao">社</div>
                                    <div
                                        v-if=" ClientInformation.shebao.payYears && ClientInformation.shebao.has == 1"
                                    >年限：{{ ClientInformation.shebao.payYears }}年；</div>
                                    <div
                                        v-if="ClientInformation.shebao.type && ClientInformation.shebao.has == 1"
                                    >类型：{{ ClientInformation.shebao.type }}；</div>
                                    <div v-if="ClientInformation.shebao.has != 1">未知</div>
                                </div>
                                <div class="quantity-item flex">
                                    <div class="baodan">保</div>
                                    <div
                                        v-if="(ClientInformation.baodan.annualFee ||ClientInformation.baodan.annualFee === 0) && ClientInformation.baodan.has == 1"
                                    >年缴：{{ ClientInformation.baodan.annualFee }}元；</div>
                                    <div
                                        v-if=" (ClientInformation.baodan.payYears ||ClientInformation.baodan.payYears === 0) && ClientInformation.baodan.has == 1"
                                    >年限：{{ ClientInformation.baodan.payYears }}年；</div>
                                    <div v-if="ClientInformation.baodan.has != 1">未知</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clue-right">
                        <div class="jl">
                            <el-tabs v-model="activeName" stretch>
                                <el-tab-pane label="跟进记录" name="first">
                                    <div
                                        v-if="ClientInformation.followLogs.length > 0"
                                        class="timeblock"
                                    >
                                        <el-timeline>
                                            <el-timeline-item
                                                v-for="(item, index) in ClientInformation.followLogs"
                                                :key="index"
                                                type="primary"
                                            >
                                                <div class="flex-center">
                                                    <div>{{ item.followedTime | getYMDHMS }}</div>
                                                    <div>({{ item.follower }})</div>
                                                </div>
                                                <h4>
                                                    <span />
                                                    <p
                                                        style="color: #4086ec"
                                                    >{{ item.modifyContent }}</p>
                                                    <p>{{ item.followContent }}</p>
                                                </h4>
                                            </el-timeline-item>
                                        </el-timeline>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="流转记录" name="second">
                                    <div
                                        v-if="ClientInformation.transferLogs.length > 0"
                                        class="timeblock"
                                    >
                                        <el-timeline>
                                            <el-timeline-item
                                                v-for="(item, index) in ClientInformation.transferLogs"
                                                :key="index"
                                                type="primary"
                                            >
                                                <div class="flex-center">
                                                    <div>{{ item.operatedTime }}</div>
                                                    <div>({{ item.operator }})</div>
                                                </div>
                                                <h4>
                                                    <span />
                                                    {{ item.content }}
                                                </h4>
                                            </el-timeline-item>
                                        </el-timeline>
                                    </div>
                                    <div v-else>
                                        <img src="@/assets/common/no-data/no-records.svg" alt />
                                        <div
                                            style="text-align:center;font-size: 12px;color: #AAAAAA"
                                        >暂无流转记录</div>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="线索记录" name="third">
                                    <div
                                        v-if="ClientInformation.clueLogs.length > 0"
                                        class="timeblock"
                                    >
                                        <el-timeline>
                                            <el-timeline-item
                                                v-for="(item, index) in ClientInformation.clueLogs"
                                                :key="index"
                                                type="primary"
                                            >
                                                <div class="flex-center">
                                                    <div>{{ item.createdTime }}</div>
                                                    <div>({{ ClueInformation.source }})</div>
                                                </div>

                                                <h4>
                                                    <span />
                                                    <p>信息：{{ item.info }}</p>
                                                    <p
                                                        style="display: flex"
                                                    >星级：{{ item.qualityLevel }}星</p>
                                                    <p>意向度：{{ item.purposeLevel }}</p>
                                                    <p>跟进结果：{{ item.status }}</p>
                                                </h4>
                                            </el-timeline-item>
                                        </el-timeline>
                                    </div>
                                    <div v-else>
                                        <img src="@/assets/common/no-data/no-records.svg" alt />
                                        <div
                                            style="text-align:center;font-size: 12px;color: #AAAAAA"
                                        >暂无线索记录</div>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="通话记录" name="fourth">
                                    <div class="timeblock">
                                        <el-timeline>
                                            <el-timeline-item
                                                v-for="(item, index) in personClueDatas"
                                                :key="index"
                                                type="primary"
                                            >
                                                <div class="flex-center">
                                                    <div>{{ item.createdTime|getYMDHMS }}</div>
                                                    <div>({{ item.callerName }})</div>
                                                </div>
                                                <div class="audio-record">
                                                    <div
                                                        class="record-item"
                                                    >{{ item.callerName }}与{{ item.calledName }}的通话</div>
                                                    <div class="record-item">
                                                        <callAudio
                                                            :src="item.audioUrl"
                                                            :font-size="'10px'"
                                                        />
                                                    </div>
                                                </div>
                                            </el-timeline-item>
                                        </el-timeline>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
                <div v-show="!clientShow" class="none">
                    <!-- <div class="nc">
            <i class="iconfont icon-chakankehuwuquanxian"></i>
            <span>很抱歉，该线索没有转化成客户，无法查看客户信息！ </span>
                    </div>-->
                    <div class="nc">
                        <img src="@/assets/common/no-data/no-data.svg" alt />
                        <div
                            style="text-align:center;font-size: 12px;color: #AAAAAA"
                        >很抱歉，该线索没有转化成客户，无法查看客户信息！</div>
                    </div>
                </div>
            </div>
            <div v-if="showBtn != 1" slot="footer" class="dialog-footer flex-sb">
                <!-- 这里做两层判断 线索拥有者和客户拥有者 通过isShow来判断点击线索还是用户 -->
                <!-- {{ClientInformation}} -->
                <div
                    v-if="ClueInformation.owner && isShow1 == 0"
                    style="font-size:14px"
                >当前归属：{{ ClueInformation.owner }}</div>
                <div
                    v-if="ClientInformation.owner && isShow1 == 1"
                    style="font-size:14px"
                >当前归属：{{ ClientInformation.owner }}</div>
                <el-button
                    v-if="ClueInformation.operational == 1 && isShow1 == 0"
                    type="primary"
                    size="mini"
                    @click="tofollowPage"
                >跟进线索</el-button>
                <el-button
                    v-if="ClueInformation.operational == 2 && isShow1 == 0"
                    type="primary"
                    size="mini"
                    @click="handleDelete"
                >抓取线索</el-button>
                <el-button
                    v-if="ClientInformation.operational == 2 && isShow1 == 1"
                    type="primary"
                    size="mini"
                    @click="handleClient"
                >抓取客户</el-button>
                <el-button
                    v-if="ClientInformation.operational == 1 && isShow1 == 1"
                    type="primary"
                    size="mini"
                    @click="toClientfollowPage"
                >跟进客户</el-button>
                <!-- operational -->
            </div>
            <div class />
        </el-dialog>
    </div>
</template>

<script>
import callAudio from '@/components/callAudio.vue';
// import {
// 	personClueData
// } from "../../../assets/js/falseData.js";
import { clue } from '@/api/my_clue';
import { clue_owner } from '@/api/clue_pool';
import { getYMDHMS } from '@/assets/js/time.js';
import { client_owner } from '@/api/sea_client';
export default {
    data() {
        return {
            personClueData: '',
            personClueDatas: '',
            visible: this.show,
            show1: false,
            titleName: ['线索信息', '客户信息'],
            isShow1: 0,
            clientShow: false,
            ClueInformation: {
                operateLogs: [],
            },
            ClientInformation: {
                mobileNumber: '',
                fundNeeds: '',
                name: '',
                sex: null,
                age: '',
                house: {
                    has: '',
                    marketPrice: '',
                    type: '',
                    paymentType: '',
                },
                vehicle: {
                    has: '',
                    nakedPrice: '',
                    paymentType: '',
                },
                shebao: {
                    has: '',
                    type: '',
                    payYears: '',
                },
                gongjijin: {
                    has: '',
                    type: '',
                    payYears: '',
                    monthlyFee: '',
                },
                baodan: {
                    has: '',
                    annualFee: '',
                    payYears: '',
                },
                id: '',
                createdTime: '',
                followStage: '',
                followLogs: [],
                transferLogs: [],
                clueLogs: [],
            },
            activeName: 'first',
            showBtn: '',
        };
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        callAudio,
    },
    watch: {
        show() {
            this.visible = this.show;
        },
    },
    methods: {
        init(id, type) {
            this.clueId = id;
            this.showBtn = type;
            clue({
                clueId: id,
            }).then((res) => {
                if (res.code == 0) {
                    this.visible = true;
                    this.ClueInformation = res.data;
                    this.personClueData = res.data.callLogs;
                    this.personClueDatas = res.data.client.callLogs;
                    if (
                        this.ClueInformation.hasBaodan == 0 &&
                        this.ClueInformation.hasGongjijin == 0 &&
                        this.ClueInformation.hasHouse == 0 &&
                        this.ClueInformation.hasShebao == 0 &&
                        this.ClueInformation.hasVehicle == 0
                    ) {
                        this.ClueInformation.isAllNone = true;
                    }
                    //线索字段的翻译
                    for (
                        let i = 0;
                        i < this.ClueInformation.operateLogs.length;
                        i++
                    ) {
                        this.ClueInformation.operateLogs[i].operatedTime =
                            getYMDHMS(
                                this.ClueInformation.operateLogs[i].operatedTime
                            );
                    }
                    let clueLoanPurposeLevel =
                        this.common.allValueNeed('loan-purpose-level');
                    let purposeVal = clueLoanPurposeLevel.find((item) => {
                        return this.ClueInformation.purposeLevel == item.number;
                    });
                    if (purposeVal)
                        this.ClueInformation.purposeLevel = purposeVal.cnVal;
                    let clueSex = this.common.allValueNeed('sex');
                    let clueSexVal = clueSex.find((item) => {
                        return this.ClueInformation.sex == item.number;
                    });
                    if (clueSexVal) this.ClueInformation.sex = clueSexVal.cnVal;
                    //判断有客户信息时
                    if (res.data.client != null && res.data.client != '') {
                        console.log('res.data.client', res.data.client);
                        this.ClientInformation = res.data.client;
                        for (
                            let i = 0;
                            i < this.ClientInformation.followLogs.length;
                            i++
                        ) {
                            this.ClientInformation.followLogs[i].followedTime =
                                getYMDHMS(
                                    this.ClientInformation.followLogs[i]
                                        .followedTime
                                );
                        }
                        for (
                            let i = 0;
                            i < this.ClientInformation.transferLogs.length;
                            i++
                        ) {
                            this.ClientInformation.transferLogs[
                                i
                            ].operatedTime = getYMDHMS(
                                this.ClientInformation.transferLogs[i]
                                    .operatedTime
                            );
                        }
                        // 处理ClientInformation线索日志字段
                        for (
                            let i = 0;
                            i < this.ClientInformation.clueLogs.length;
                            i++
                        ) {
                            this.ClientInformation.clueLogs[i].createdTime =
                                getYMDHMS(
                                    this.ClientInformation.clueLogs[i]
                                        .createdTime
                                );
                            let clueStatus =
                                this.common.allValueNeed('clue-status');
                            let clueStatusVal = clueStatus.find((item) => {
                                return (
                                    this.ClientInformation.clueLogs[i].status ==
                                    item.number
                                );
                            });
                            if (clueStatusVal)
                                this.ClientInformation.clueLogs[i].status =
                                    clueStatusVal.cnVal;
                            // 意向度-意向线索-等级
                            let loanPurposeLevel =
                                this.common.allValueNeed('loan-purpose-level');
                            let PurposeLevelVal = loanPurposeLevel.find(
                                (item) => {
                                    return (
                                        this.ClientInformation.clueLogs[i]
                                            .purposeLevel == item.number
                                    );
                                }
                            );
                            if (PurposeLevelVal)
                                this.ClientInformation.clueLogs[
                                    i
                                ].purposeLevel = PurposeLevelVal.cnVal;
                            // 客户性别
                            let clientSex = this.common.allValueNeed('sex');
                            let clientSexVal = clientSex.find((item) => {
                                return (
                                    this.ClientInformation.sex == item.number
                                );
                            });
                            if (clientSexVal)
                                this.ClientInformation.sex = clientSexVal.cnVal;

                            // 客户线索跟进状态
                            let clientFollowStage = this.common.allValueNeed(
                                'client-follow-stage'
                            );
                            let loanPurposeLevelVal = clientFollowStage.find(
                                (item) => {
                                    return (
                                        this.ClientInformation.followStage ==
                                        item.number
                                    );
                                }
                            );
                            console.log(loanPurposeLevelVal);
                            if (loanPurposeLevelVal)
                                this.ClientInformation.followStage =
                                    loanPurposeLevelVal.cnVal;
                            let vehiclePayType =
                                this.common.allValueNeed('vehicle-pay-type');
                            let vehicleVal = vehiclePayType.find((item) => {
                                return (
                                    this.ClientInformation.vehicle
                                        .paymentType == item.number
                                );
                            });
                            if (vehicleVal)
                                this.ClientInformation.vehicle.paymentType =
                                    vehicleVal.cnVal;

                            // house-pay-type
                            let housePayType =
                                this.common.allValueNeed('house-pay-type');
                            let houseVal = housePayType.find((item) => {
                                return (
                                    this.ClientInformation.house.paymentType ==
                                    item.number
                                );
                            });
                            if (houseVal)
                                this.ClientInformation.house.paymentType =
                                    houseVal.cnVal;

                            // house-type
                            let houseType =
                                this.common.allValueNeed('house-type');
                            let houseTypeVal = houseType.find((item) => {
                                return (
                                    this.ClientInformation.house.type ==
                                    item.number
                                );
                            });
                            if (houseTypeVal)
                                this.ClientInformation.house.type =
                                    houseTypeVal.cnVal;
                            // gongjijin-pay-type
                            let gongjijinPayType =
                                this.common.allValueNeed('gongjijin-pay-type');
                            let gongjijinVal = gongjijinPayType.find((item) => {
                                return (
                                    this.ClientInformation.gongjijin.type ==
                                    item.number
                                );
                            });
                            if (gongjijinVal)
                                this.ClientInformation.gongjijin.type =
                                    gongjijinVal.cnVal;
                            // shebao-type
                            let shebaoType =
                                this.common.allValueNeed('shebao-type');
                            let shebaoTypeVal = shebaoType.find((item) => {
                                return (
                                    this.ClientInformation.shebao.type ==
                                    item.number
                                );
                            });
                            if (shebaoTypeVal)
                                this.ClientInformation.shebao.type =
                                    shebaoTypeVal.cnVal;

                            console.log(
                                'this.ClientInformation',
                                this.ClientInformation
                            );
                        }
                        this.clientShow = true;
                    } else {
                        this.clientShow = false;
                    }
                } else {
                    this.visible = false;
                    this.$message({
                        message: res.message,
                        type: 'warning',
                    });
                }
            });
        },
        selectTitle(e) {
            this.isShow1 = e;
        },
        handleDialogClose() {
            this.visible = false;
            this.$emit('update:show', false);
            this.ClientInformation.owner = '';
            this.ClueInformation.owner = '';
            this.ClueInformation.operational = '';
            this.ClientInformation.operational = '';
            this.isShow1 = 0;
        },
        // 抓取线索
        handleDelete() {
            // console.log(this.clueId);
            // clue_owner()
            clue_owner({
                clueId: this.clueId,
            }).then((res) => {
                if (res.code == 0) {
                    this.$message.success('抓取成功');
                    this.visible = false;
                    this.$emit('refresh');
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 抓取客户
        handleClient() {
            alert('抓取客户');
            console.log(this.ClientInformation.id);
            client_owner({
                clientId: this.ClientInformation.id,
            }).then((res) => {
                if (res.code == 0) {
                    this.$message.success('抓取成功');
                    this.visible = false;
                    this.$emit('refresh');
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 跟进客户
        toClientfollowPage() {
            this.visible = false;
            let index = this.ClientInformation;
            this.$emit('cilentFolloePage', index);
        },
        // 跳跟进线索
        tofollowPage() {
            this.visible = false;
            let data = {
                id: this.clueId,
            };
            this.$emit('followPage', data);
        },
    },
};
</script>
<style lang="less" scoped>
@import '../../../css/table.less';

.flex-center {
    display: flex;
    align-items: center;
    height: 16px;
    font-size: 14px;
    color: #aaaaaa;
}

.flex {
    display: flex;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
}

::v-deep .el-dialog {
    height: 70vh;
    position: relative;
    overflow: auto;
    width: 60%;
}

//线索和客户信息弹窗
.tank {
    ::v-deep .el-dialog__header {
        padding: 0;
    }

    ::v-deep .el-dialog__body {
        height: 100%;
        padding: 0;
    }

    //选项卡
    h2 {
        border: 2px solid #eeeff3;
        text-align: center;
        padding: 10px 0;

        span {
            cursor: pointer;
            font-size: 16px;
            font-weight: 300;
            color: #aaaaaa;
            display: inline-block;
            padding: 5px 10px;
            border: 1px solid#EEEFF3;
        }

        span.active {
            background: rgba(236, 243, 253, 1);
            color: #4086ec;
            font-weight: 400;
        }

        span:first-child {
            border-right: 0px solid#EEEFF3;
            border-radius: 5px 0px 0px 5px;
        }

        span:last-child {
            border-left: 0px solid#EEEFF3;
            border-radius: 0px 5px 5px 0px;
        }
    }

    //线索信息
    .clue {
        overflow: hidden;
        height: calc(100% - 60px);

        .clue-left {
            width: 50%;
            float: left;
            height: 100%;
            border-right: 1px solid #eeeff3;
            padding: 0 20px;
            overflow-y: scroll;

            .l-top,
            .l-bottom {
                h4 {
                    padding: 7px 0;
                    border-bottom: 1px solid #dcdfe6;
                    display: flex;
                    justify-content: space-between;
                    color: #555555;

                    .xingzhi {
                        color: #aaaaaa;
                        font-size: 13px;
                    }

                    ::v-deep .el-rate {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 5px;

                        .el-rate__icon {
                            margin-right: 0;
                        }
                    }
                }

                .contant {
                    margin-bottom: 10px;
                    padding-left: 10px;
                    font-size: 13px;
                    color: #555555;

                    .item-info {
                        border-bottom: 1px solid #dcdfe6;
                        align-items: center;
                        // padding: 10px 0;
                        margin-left: 10px;
                        height: 40px;

                        div,
                        img {
                            height: 40px;
                            line-height: 40px;
                        }

                        &:last-child {
                            text-align: center;

                            div,
                            img {
                                height: 20px;
                                line-height: 20px;
                            }
                        }

                        img {
                            margin-right: 10px;
                        }
                    }

                    .remark-item {
                        min-height: 72px;
                        background-color: rgba(250, 250, 250, 1);

                        &:last-child {
                            margin-top: 10px;
                        }
                    }

                    // p {
                    //   margin-bottom: 14px;
                    //   color: #555;
                    // }
                    // em {
                    //   font-size: 14px;
                    //   padding: 3px 5px;
                    //   border-radius: 30px;
                    //   margin-right: 15px;
                    // }
                    // .car {
                    //   color: #4086ec;
                    //   border: 1px solid #4086ec;
                    // }
                    // .house {
                    //   color: #ed5939;
                    //   border: 1px solid #ed5939;
                    // }
                    // .gongjijin {
                    //   color: #e48900;
                    //   border: 1px solid #e48900;
                    // }
                    // .shebao {
                    //   color: #88c91d;
                    //   border: 1px solid #88c91d;
                    // }
                    // .baodan {
                    //   color: #557ebc;
                    //   border: 1px solid #557ebc;
                    // }
                }
            }
        }

        .clue-right {
            width: 50%;
            float: left;
            height: 100%;
            border-left: 1px solid #eeeff3;
            padding: 0 20px;

            .jl {
                height: 100%;

                ::v-deep .el-tabs {
                    height: 100%;

                    .el-tabs__content {
                        height: calc(100% - 40px);
                        overflow: auto;
                    }
                }
            }
        }

        .clue-right > h4 {
            padding: 7px 0;
            border-bottom: 1px solid #dcdfe6;
            color: #555555;
        }
    }

    .timeblock {
        padding-top: 10px;
        overflow-y: scroll;
        height: 88%;

        .audio-record {
            margin-top: 10px;
            // margin: 5px 0 5px 0;
            color: #ffffff;
            width: 300px;
            height: 84px;
            background-color: #3f81e7;

            .record-item {
                margin-left: 10px;
                padding-top: 10px;
            }
        }

        ::v-deep .el-timeline-item__tail {
            border-left: 1px solid #3f85eb;
        }

        ::v-deep .el-timeline-item {
        }

        ::v-deep.el-timeline-item__node--primary {
            border: 2px solid #3f85eb;
            background: #fff;
        }

        ::v-deep .el-timeline-item__tail {
            top: 10px;
            left: 6.5px;
            // height: 80%;
        }

        h4 {
            color: #555;
        }

        time,
        span {
            color: #c9c9c9;
        }
    }

    .baoguo {
        height: 100%;
    }

    .cl-bottom,
    .jl {
        h4 {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            padding: 10px;
            background: #f6f9ff;
            border-radius: 4px;
        }

        ::v-deep .el-timeline-item__tail {
            top: 10px;
            left: 7px;
            height: 100%;
        }
    }

    .none {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .nc {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            i {
                font-size: 120px;
            }

            span {
                margin-top: 20px;
                display: inline-block;
                width: 100%;
                text-align: center;
            }
        }
    }

    .client .k {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        p {
            margin-bottom: 0 !important;
            margin-left: 15px;
        }
    }

    .client .k > span {
        font-size: 14px;
        padding: 3px 6px;
        border-radius: 30px;
        display: inline-block;
    }

    .car {
        color: #4086ec;
        border: 1px solid #4086ec;
    }

    .house {
        height: 100%;
        color: #ed5939;
        border: 1px solid #ed5939;
    }

    .gongjijin {
        color: #e48900;
        border: 1px solid #e48900;
    }

    .shebao {
        color: #88c91d;
        border: 1px solid #88c91d;
    }

    .baodan {
        color: #557ebc;
        border: 1px solid #557ebc;
    }
}

.title-info {
    margin-top: 10px;
    margin-left: 10px;

    // padding-bottom: 5px;
    .star {
        cursor: pointer;
        color: #4086ec;
    }
}

.hasIcon {
    div {
        margin-right: 2px;
        width: 20px;
        border-radius: 50%;
    }
}

.time-line-content {
    margin-top: 10px;
    padding-left: 5px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-left: 5px solid rgba(64, 134, 236, 1);
    background-color: rgba(246, 249, 255, 1);
    border-radius: 5px 0px 0px 5px;
}

.quantity-item {
    align-items: center;

    div {
        margin-top: 10px;

        &:first-child {
            height: 30px;
            width: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 50%;
            margin-right: 5px;
        }
    }
}

/deep/ .el-dialog {
    min-width: 681px;
}

/deep/ .el-timeline-item__node--normal {
    left: 0;
}
</style>
